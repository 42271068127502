import { useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useTimeout from '../hooks/use-timeout';
import { useState, useEffect, useMemo } from 'react';
import FlashOne from '../components/FlashOne';
import FlashMany from '../components/FlashMany';
import Hidden from '../components/Hidden';
import { useNavigate, useParams } from 'react-router-dom';
import Video from '../components/video2022';
import Fireworks from '../components/Fireworks';
import Tooltip from '@mui/material/Tooltip';

const D13Link = 'https://d13.co/isitalgorandsbirthday/';

function NFT({countdown}) {
	const [startVideo, setStartVideo] = useState(false);
	const [showNFT, setShowNFT] = useState(null);
	const navigate = useNavigate();

	const initDelay = 5000;
	const duration = 5 * 4000;

	const [defaultAnimType, setDefaultAnimType] = useState(true);

	useTimeout(() => {
		console.log('showing now');
		v.current.style.setProperty('display', 'flex');
		const videos = [...v.current.getElementsByTagName('video')];
		const useDefAnim = videos[0].currentSrc.endsWith('webm');
		console.log('set default anim', useDefAnim);
		const animation = useDefAnim ? 'fade-in' : 'i-fade-in';
		v.current.style.setProperty('animation', `${animation} ${duration}ms ease-in-out 1 forwards`);
		videos.forEach(v => v.play());
	}, initDelay);

	const gotoNFT = useCallback(() => {
		navigate('/NFT/2024');
	}, []);

	const [year, setYear] = useState();

	const flashMany = useMemo(() => {
		return { values: [
			'2019',
			'2020',
			'2021',
			'2022',
			'2023',
			'2024',
			'',
			'',
			5,
			'Years',
			'Algorand',
			'MainNet',
			'',
			'',
			'Since',
			'Block',
			'Zero',
			'on',
			11,
			'June',
			2019,
			'00:00',
			'UTC',
			'',
			'',
			'1.9',
			'Billion',
			'Txns',
			'',
			'10000',
			'TPS',
			'',
			'2.8s',
			'Finality',
			'',
			'Zero',
			'Downtime',
			'',
			'',
			'Fast',
			'Green',
			'Secure',
			'Scalable',
			'Affordable',
			'',
			'',
			'The',
			'Best',
			'Blockchain',
			'Experience',
			'is',
			'Here',
			'',
			'',
			'Happy',
			'Birthday',
			'Algorand',
		], delay: initDelay + duration - 300 - 1000, stepDuration: 350, firstMultiply: 3, lastMultiply: 2 };
	}, []);

	const gotoD13 = useCallback(() => {
		if (!window.open(D13Link)) {
			navigate(D13Link);
		}
	}, []);

	const v = useRef();
	const mRef = useRef();

	const lastShown = useCallback(() => {
		// v.current.style.setProperty('mix-blend-mode', 'normal', 'important');
		// NOT WORKING mRef.current.style.setProperty('animation', 'ranima 3s forwards', 'important');
		// v.current.style.setProperty('transform','scale(1)', 'important');
		mRef.current.style.setProperty('z-index', -1, 'important');
		const animation = 'anima';
		v.current.style.setProperty('animation', `${animation} 2s forwards`);
		// [...v.current.getElementsByTagName('video')].map(v => { v.loop = true; v.play() });
	}, [v, mRef, defaultAnimType]);

	const lastDone = useCallback(()=> {
			setShowNFT(true);
			// let pb = 1;
			// const s = setInterval(() => {
			// 	pb *= 2
			// 	pb = Math.min(pb, 10);
			// 	[...v.current.getElementsByTagName('video')].map(v => v.playbackRate = pb / 10)
			// 	if (pb >= 10) {
			// 		clearInterval(s);
			// 	}
			// }, 1200);
	}, [v]);

	const onStart = useCallback(() => {
		// [...v.current.getElementsByTagName('video')].map(v => v.loop = false);
	}, [v]);

	console.log('render', startVideo);

  return <>
		<Box sx={{overflow: 'hidden', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			<Box ref={v} sx={{ display: 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
				<Video start={startVideo} />
			</Box>
			<Hidden show={showNFT} delay={2500} flicker={true}>
				<Button variant="outlined" sx={{ animation: 'btn-glow 1s linear infinite', mt: 5, textTransform: 'none' }} onClick={gotoNFT}>
					VIEW 4th BIRTHDAY NFT
				</Button>
			</Hidden>
			<Hidden show={showNFT} delay={2500}>
				<Box sx={{position: 'absolute', bottom: 20, left: '50%', zIndex: 1, transform: 'translate(-50%, 0)', animation: 'fade-pulse 6s linear infinite, logo-in 15s linear forwards', opacity: 0.3}}>
					<a href={D13Link} target="_blank">
						<img src="/D13-green.png" />
					</a>
				</Box>
			</Hidden>
		</Box>
		<Box ref={mRef} sx={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 2, mixBlendMode: 'color-dodge', opacity: 0.7, color: '#76f935' }}>
			<FlashMany config={flashMany} onStart={onStart} onLastShow={lastShown} onLastDone={lastDone} />
		</Box>
		<Fireworks />
  </>
}

export default NFT;
