import { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import ConnectButton from '../components/connect-button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useParams, Link } from 'react-router-dom';
import A from '@mui/material/Link';
import NFTVideo from '../components/nft2023';
import { T, P } from '../components/Typography';
import theme from '../theme';
import api from '../api';
import { VFlex, HFlex } from '../components/layout';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { countdown } from '../state/index';
import { SpinnerRound } from 'spinners-react';
import { NFT2023ID } from '../constants.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const boolToHuman = val => val === false ? 'No' : val === true ? 'Yes' : val;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const D = () => <Divider sx={{ m: 4, mb: 4 }} />;

const D2 = () => <Divider sx={{ m: 2, mb: 2 }} />;

const Loading = () => <Box sx={{width: 100}}><LinearProgress /></Box>;

const snackProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
};

const AvailableFor = observer(({ until, countdown }) => {
  if (!until) {
    return 'Not available';
  }
  if (countdown.serverNow > until) {
    console.log(countdown.serverNow, until);
    return 'Expired';
  } else if (!countdown.serverNow) {
    return <Loading />
  } else {
    const d = new Date(1000 * (until - countdown.serverNow));
    return d.toISOString().split(/[T.]/)[1]
  }
});

function getButtonLabelForStep(step) {
  return <><SpinnerRound size={25} color="#777" style={{marginRight: 7}} />{getButtonTextForStep(step)}</>;
}

function getButtonTextForStep(step) {
  switch(step) {
    case 1: return 'Checking';
    case 2: return 'awaiting approval';
    case 3: return 'Submitting';
    case 4: return 'Confirming';
    case 5: return 'Success';
    default: return 'Please wait';
  }
}

const emptyInfo = {
  price: 40,
  coop: 55,
  assetID: NFT2023ID,
};

const buttonLabels = [
  (usd) => 'Receive NFT drop',
  (usd) => `Buy NFT (Ⱥ${emptyInfo.price})`,
  (usd) => `Buy NFT (©${emptyInfo.coop})`,
];

function N({ account, discountToken, NFT }) {
  const [buttonLabel, setButtonLabel] = useState(buttonLabels[1]());
  const [buttonInner, setButtonInner] = useState(buttonLabel);
  const [claimed, setClaimed] = useState(110);
  const [price, setPrice] = useState(``);
  const [availableFor, setAvailableFor] = useState(<Loading />);
  const [claimedLabel, setClaimedLabel] = useState('110/200');
  const [costUSD, setCostUSD] = useState();
  const [coopDiscount, setCoopDiscount] = useState();
  const [showWTF, setShowWTF] = useState(false);

  const navigate = useNavigate();

  const [buying, setBuying] = useState(false);
  const [txId, setTxId] = useState();
  const [step, setStep] = useState();
  const [t, setT] = useState();
  const [info, setInfo] = useState(emptyInfo);
  const [free, setFree] = useState(false);

  const [algoUSD, setAlgoUSD] = useState();
  const [coopUSD, setCoopUSD] = useState();
  const [buyWithCoop, setBuyWithCoop] = useState(false);

  useEffect(() => {
    (async () => {
      const resp = await fetch('https://free-api.vestige.fi/asset/796425061/price');
      const { USD, price } = await resp.json();
      const algoUSD = USD / price;
      const coopUSD = price * algoUSD;
      const a = emptyInfo.price * algoUSD;
      const b = emptyInfo.coop * coopUSD;
      setAlgoUSD(a);
      setCoopUSD(b);
      setCoopDiscount(Math.round((a - b)/a * 100));
    })()
  }, []);

  useEffect(() => {
    if (free) {
      setButtonLabel(buttonLabels[0](0))
      setCostUSD(0);
    } else if (buyWithCoop) {
      setShowWTF(true);
      setButtonLabel(buttonLabels[2](coopUSD))
      setCostUSD(coopUSD);
    } else {
      setButtonLabel(buttonLabels[1](algoUSD))
      setCostUSD(algoUSD);
    }
  }, [buyWithCoop, coopUSD, algoUSD, free]);

  useEffect(() => {
    setButtonInner(buying ? getButtonLabelForStep(step) : buttonLabel);
  }, [buying, step, buttonLabel]);

  const gotoThanks = useCallback(() => {
    if (txId)
      navigate(`/NFT/2023/thanks#${encodeURIComponent(txId)}`);
  }, [txId]);

  useEffect(() => {
    if (step === 2 && !t)
      setT(setTimeout(() => {
        api.warning('Nothing in your wallet yet? Try reloading the page and start over', -1, 'no-tx', snackProps);
      }, 20000));
    if (step > 2 && t) {
      clearTimeout(t);
      setT();
    }
    if (step === 5) {
      setTimeout(gotoThanks, 2000);
    }
    if (step > 2) {
      api.closeSnackbar('no-tx');
    }
  }, [step, t]);

  useEffect(() => {
    if (!account?.address && buying) {
      setBuying(false);
      api.closeSnackbar('no-tx');
    }
  }, [buying, account?.address]);

  const updateProgress = useCallback((error, data) => {
    console.log('error', error);
    console.log('data', data);
    if (data?.error || error || data?.close) {
      setBuying(false);
    }
    const hasError = data?.error || error
    if (hasError) {
      api.error(hasError, 0, 'txe', snackProps);
      api.closeSnackbar('no-tx');
      api.closeSnackbar('tx');
      return;
    }
    if (data?.txId || data?.gtxId) {
      setTxId(data.gtxId ?? data.txId);
    }
    if (data?.step) {
      setStep(data.step);
      const msg = `${data.step}/5 ${data.message}`;
      api.info(msg, 0, 'tx', snackProps);
    }
  }, [txId]);

  const buyNFT = useCallback(() => {
    setTxId();
    setBuying(true);
    api.closeSnackbar('no-tx');
    api.closeSnackbar('tx');
    api.closeSnackbar('txe');
    (async() => {
      try {
        const data = await api.subscribe({cmd: 'get-nft', coop: buyWithCoop, discountToken: discountToken.token}, updateProgress);
      } catch(e) {
        setBuying(false);
        api.error(e.message, 0, 'txe', snackProps);
      }
    })()
  }, [buyWithCoop]);

  useEffect(() => { (async function x() {
    try {
      const data = await api.query({cmd: 'check-availability', discountToken: discountToken.token });
      
      if (data.free) {
        setFree(true);
      }

      setInfo(data);
      NFT.setId(data.assetID);

      const { claimed, total, availableUntil } = data;
      setAvailableFor(<AvailableFor until={availableUntil} countdown={countdown} />);
      setClaimedLabel(`${claimed}/${total}`);
    } catch(e) {
      api.error(e.message);
    }
  })() }, [discountToken.token, account?.address]);

  const changeBuyWithCoop = useCallback(() => {
    setBuyWithCoop(!buyWithCoop);
  }, [buyWithCoop]);

  return <VFlex>
    <Box sx={{ maxWidth: { sm: '300px', md: '450px' }}}>
      <Typography align="center" variant="h1" color="primary" sx={{ mb: 3 }}>Buy 2023 Algorand Birthday NFT</Typography>
      <Box sx={{ display: 'flex', mb: 2, flexDirection: 'row', justifyContent: 'center' }}>
        <NFTVideo />
      </Box>
      <HFlex sx={{mt: 3}}>
        <Box sx={{flexGrow: 1}}>Asset ID</Box>
        <Typography>{info.assetID ?? NFT.id}</Typography>
      </HFlex>
      <HFlex>
        <Box sx={{flexGrow: 1}}>Claimed</Box>
        <Typography>{claimedLabel}</Typography>
      </HFlex>
      <HFlex>
        <Box sx={{flexGrow: 1}}>Price</Box>
        {
          discountToken.token ? 
            <Typography>FREE <span style={{textDecoration: 'line-through'}}>Ⱥ{info.price ?? emptyInfo.price}</span></Typography>
          : <Typography>Ⱥ {info.price ?? emptyInfo.price}</Typography>
        }
      </HFlex>
      <HFlex>
        <Box sx={{flexGrow: 1}}>Available during</Box>
        <Typography>June 11, 2023</Typography>
      </HFlex>

      { info.available && account.address && <>
        <D />

        <HFlex>
          <Box sx={{flexGrow: 1}}>Eligible</Box>
          <Typography>{!account.address ? '?' : boolToHuman(info.eligible)}</Typography>
        </HFlex>
        <HFlex hide={!info.free}>
          <Box sx={{flexGrow: 1}}>Discount Eliglble</Box>
          <Typography>Yes</Typography>
        </HFlex>
        <HFlex hide={!info.free}>
          <Box sx={{flexGrow: 1}}>Your Price</Box>
          <Typography>0 Ⱥ</Typography>
        </HFlex>
      </>
      }
      <D />
      { account?.address && info.available && info.eligible && <>
        <VFlex>
          { txId && <HFlex sx={{mb: 2}}>{ step < 5 ? 'Pending': '' } Transaction ID: {txId}</HFlex> }
          <VFlex>
            <FormGroup sx={{alignItems: 'center'}}>
              <Button sx={{mb: 1}} disabled={buying} onClick={buyNFT} variant="contained" color="primary">{buttonInner}</Button>
              { !free && <><Typography sx={{mb: 1}}>Approx. {printUSD(costUSD)}</Typography>
              <FormControlLabel control={<Checkbox onChange={changeBuyWithCoop} value={buyWithCoop} />} label={`$COOP Discount${coopDiscount ? " ("+coopDiscount+"%)" : ""}`} />
                { showWTF ? <Typography><A target="blank" href="https://coop.hair">What the hell is $coop?</A></Typography> : null }
              </> }
    </FormGroup>
          </VFlex>
        </VFlex>
        <D />
      </> }
      { account?.address && info.total && (!info.available || !info.eligible) && <>
        <VFlex>
          { txId && <HFlex sx={{mb: 2}}>{ step < 5 ? 'Pending': '' } Transaction ID: {txId}</HFlex> }
          <HFlex><Button disabled={true} onClick={buyNFT} variant="contained" color="primary">{ !info.total ? 'Supply ran out' : !info.eliglble ? 'Limit 1 per account' : 'Purchase not available' }</Button></HFlex>
        </VFlex>
        <D />
      </> }
    </Box>
  </VFlex>
}

export default observer(N);


export function printNumber(val, prefix='') {
  if (typeof val !== "number")
    return val;
  return `${prefix}${val.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
}

export function printUSD(val) {
  return printNumber(val, '$');
}
